.request-modal {
    outline: none;
    margin: 0;
    max-width: 500px;
    width: 100%;
    height: 300px;
    background: white;
    border-radius: 20px;
    position: relative;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    transform: translateY(-100vh);
    transition: transform 200ms ease-in-out;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: normal;
}

.ReactModal__Overlay.request-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    padding: 30px 50px
}

@media screen and (max-width: 500px) {
    .ReactModal__Overlay.request-overlay {
        padding: 20px
    }
}

.ReactModal__Overlay--after-open.request-overlay {
    opacity: 1;
}

.ReactModal__Overlay--before-close.request-overlay {
    opacity: 0;
}

.ReactModal__Overlay--after-open .request-modal {
    transform: translateY(0);
}

.ReactModal__Overlay--before-close .request-modal {
    transform: translateY(-100vh);
}

.request-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    height: 50px !important;
    width: 100%;
}

.request-modal-header h2 {
    font-size: 18px;
    color: #222;
    text-align: center;
}

.request-modal-close {
    position: absolute;
    display: flex;
    right: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    cursor: pointer;
}

.request-modal-close img {
    width: 12px;
    height: 12px;
    object-fit: contain;
}

.request-form {
    padding: 20px;
}