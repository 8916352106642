.mobile-tab {
    display: none;
}

@media screen and (max-width: 768px) {

    .mobile-tab {
        position: fixed;
        z-index: 100;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        width: 100%;
        box-shadow: 0 0 20px #707070;
        height: 60px;
    }

    .mobile-tab-item {
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mobile-tab-name {
        font-size: 12px;
        color: #222;
        margin-top: 5px;
    }

    .mobile-tab-item.active .mobile-tab-name {
        font-weight: bold;
        color: #000;
    }

    .mobile-tab-item img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        opacity: 0.5;
    }

    .mobile-tab-item.active img {
        opacity: 1;
    }

    .mobile-tab-phone {
        position: absolute;
        right: 10px;
        bottom: 70px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #8bc34a;
        padding: 0 20px;
        box-shadow: 0 0 10px #8bc34a;
        border-radius: 20px;
        color: white;
        font-weight: bold;
    }

    .mobile-tab-phone img {
        width: 18px;
        height: 18px;
        object-fit: contain;
        filter: invert(1);
        margin-right: 5px;
    }
}





.mobile-category {
    padding: 40px 20px;
    margin-bottom: 50px;
}

.mobile-category h1 {
    font-size: 22px;
    color: #222;
    text-transform: uppercase;
}

.mobile-category-inner {
    margin-top: 20px;
}

.mobile-category-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #cecece;
}

.mobile-category-item img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.mobile-category-name {
    font-size: 14px;
    font-weight: bold;
    color: #222;
    padding-left: 10px;
}



.mobile-more-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #cecece;
    margin: 0 20px;
    color: #222;
    font-size: 14px;
}

.mobile-more-item img {
    width: 12px;
    height: 12px;
    object-fit: contain;
    opacity: 0.3;
}

.mobile-more-bottom {
    margin-top: 10px;
    padding: 20px;
}