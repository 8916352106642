@font-face {
    font-family: 'Google Sans';
    src: url('../fonts/GoogleSans-Regular.ttf');
    src: url('../fonts/GoogleSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../fonts/GoogleSans-Medium.ttf');
    src: url('../fonts/GoogleSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../fonts/GoogleSans-Bold.ttf');
    src: url('../fonts/GoogleSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay-Black.ttf');
    src: url('../fonts/RedHatDisplay-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay-ExtraBold.ttf');
    src: url('../fonts/RedHatDisplay-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay-Bold.ttf');
    src: url('../fonts/RedHatDisplay-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay-SemiBold.ttf');
    src: url('../fonts/RedHatDisplay-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay-Medium.ttf');
    src: url('../fonts/RedHatDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay-Regular.ttf');
    src: url('../fonts/RedHatDisplay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay-Light.ttf');
    src: url('../fonts/RedHatDisplay-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

:root {
    --primary: #9A0B0D;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Google Sans', sans-serif;
}

a {
    text-decoration: none;
}

html,
body,
#root {
    min-height: 100vh;
    position: relative;
}

.picture {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    justify-content: center;
    align-items: center;
}

.picture img {
    transition: opacity 0.2s;
}

.picture img.default {
    position: absolute;
}


/* NOTIFICATION STYLES */
.notification-popup {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.notification {
    color: white;
    font-size: 14px;
    display: flex;
}

.notification-html {
    margin-right: 20px !important;
    margin-left: 0 !important;
}

.notification-icon {
    font-size: 6px;
    margin-right: 10px !important;
    margin-left: 0 !important;
}