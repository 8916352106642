.form-input {
    width: 100%;
    padding: 7px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.form-input-label {
    position: absolute;
    left: 25px;
    font-size: 13px;
    font-weight: 500;
    transition: transform 0.2s, font-size 0.2s;
}

.form-input-label span {
    color: #f44336
}

.form-input-focused .form-input-label {
    transform: translateY(-25px);
    background: white;
    font-size: 12px;
}

.form-input input {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background: white;
    border: 1px solid #c3c3c3;
    padding: 0 25px;
    font-size: 14px;
    outline: none;
    caret-color: var(--primary);
    transition: border-color 0.4s;
}

.form-input input:focus {
    border-color: var(--primary);
}

.form-textarea {
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.form-textarea textarea {
    width: 100%;
    height: 120px;
    border-radius: 10px;
    background: white;
    border: 1px solid #c3c3c3;
    padding: 10px 25px;
    font-size: 14px;
    outline: none;
    caret-color: var(--primary);
    transition: border-color 0.4s;
}

.form-textarea textarea:focus {
    border-color: var(--primary);
}

.form-textarea-label {
    position: absolute;
    left: 25px;
    top: 10px;
    font-size: 13px;
    font-weight: 500;
    transition: transform 0.2s, font-size 0.2s;
}

.form-textarea-label span {
    color: #f44336
}

.form-textarea-focused .form-textarea-label {
    transform: translateY(-18px);
    background: white;
    font-size: 12px;
}

.form-input-error {
    position: absolute;
    background: #f44336;
    right: 20px;
    bottom: 0;
    font-size: 12px;
    font-weight: bold;
    padding: 3px 5px;
    border-radius: 5px;
    color: white;
}

.form-input-secure {
    position: absolute;
    z-index: 2;
    right: 5px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.form-input-secure img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    opacity: 0.5;
}

.form-input-disabled {
    width: 100%;
    margin: 7px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: #f0f0f0;
    border: 1px solid #dedede;
    padding: 0 25px;
    font-size: 14px;
    color: #444
}

.form-input-disabled-label {
    position: absolute;
    left: 25px;
    font-weight: 500;
    transform: translateY(-25px);
    background: transparent;
    font-size: 12px;
}