.page {
    padding-top: 140px;
}

.page.home-page {
    padding-top: 0;
}

.page-content {
    min-height: calc(100vh - 200px);
    padding: 40px 0;
}

.search-wrapper {
    max-width: 1240px;
    padding: 0 10px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.page-content h1 {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: #141414;
}

@media screen and (max-width: 950px) {
    .page {
        padding-top: 50px;
    }
}

@media screen and (max-width: 500px) {
    .page-content h1 {
        font-size: 22px;
    }
}


/* Page 404 Styles */
.page-404 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.page-404-title {
    font-size: 80px;
    font-family: 'Red Hat Display';
    font-weight: 900;
    color: #999;
    text-align: center;
}

.page-404-text {
    font-size: 14px;
    color: #222;
    text-align: center;
    margin-bottom: 10px;
}

.page-404-button {
    width: 150px;
    height: 50px;
    border-radius: 10px;
    background: var(--primary);
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.3s;
}

.page-404-button:hover {
    box-shadow: 0 0 10px var(--primary);
}




/* About Styles */
.about {
    margin: 0 auto;
    max-width: 1240px;
    padding: 0 20px;
}

.about h3 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    color: #141414;
    text-indent: 15px;
}

.about p {
    font-size: 15px;
    margin: 5px 0;
    text-indent: 15px;
    color: #141414;
    text-align: justify;
}

.about-information {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
}

.about-information-text {
    width: calc(100% - 340px);
}

.about-video {
    width: 300px;
    height: 100%;
    margin-top: 8px;
    border-radius: 10px;
    overflow: hidden;
}

.about-information.reverse {
    margin-top: 50px;
}

@media screen and (max-width: 768px) {
    .about-information {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .about-information.reverse {
        flex-direction: column-reverse;
    }

    .about-information-text {
        width: 100%;
    }

    .about-video {
        width: 100%;
    }
}