.catalog-wrapper {
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto;
}

.catalog-wrapper h1 {
    text-align: left;
    margin-bottom: 0;
}


/* Bread Crump */
.bread-crump {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 0;
}

.bread-crump a {
    font-size: 13px;
    font-weight: bold;
    color: #222;
}

.bread-crump a::after {
    content: "→";
    margin: 0 5px;
}

.bread-crump span {
    font-size: 13px;
    color: #555;
}


/* Loading */
.catalog-loading {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
}

.catalog-loading span {
    font-size: 30px;
}


/* Container */
.catalog-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.catalog-menu {
    width: 300px;
}

.catalog-products {
    width: calc(100% - 300px);
}

.catalog-products-empty {
    width: calc(100% - 300px);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    padding-bottom: 100px;
}

.catalog-products-inner {
    display: flex;
    flex-wrap: wrap;
}

.catalog-products .home-catalog-product {
    width: 33.3333%;
    border: 1px solid #ececec;
}



/* Category */
.catalog-category {
    width: 280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #fcfcfc;
    border: 1px solid #ececec;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.catalog-category a {
    color: #222;
    font-size: 15px;
    margin: 7px 0;
    position: relative;
    padding: 3px 0;
    overflow: hidden;
}

.catalog-category a::after {
    content: "";
    position: absolute;
    height: 1.5px;
    width: 100%;
    background: #000;
    z-index: 1;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translateX(-100%);
    transition: transform 0.3s
}

.catalog-category a:hover::after {
    transform: translateX(0);
}

.catalog-category a.active {
    font-weight: 900;
}

.catalog-category a.active:hover::after {
    transform: translateX(0);
}

.catalog-category a.active::after {
    transform: translateX(0);
}


/* Brand */
.catalog-brand {
    width: 280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #fcfcfc;
    border: 1px solid #ececec;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.catalog-brand-item {
    padding: 10px 0;
    font-size: 14px;
    color: #222;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.catalog-brand-checked {
    width: 20px;
    height: 20px;
    background: #e2e2e2;
    margin-right: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.catalog-brand-checked.active {
    background: var(--primary);
}

.catalog-brand-checked img {
    width: 12px;
    height: 12px;
    object-fit: contain;
}


@media screen and (max-width: 950px) {
    .catalog-products .home-catalog-product {
        width: 50%;
    }

    .catalog-products {
        width: calc(100% - 220px);
    }

    .catalog-products-empty {
        width: calc(100% - 220px);
    }

    .catalog-menu {
        width: 220px;
    }

    .catalog-brand,
    .catalog-category {
        width: 200px;
    }

}

@media screen and (max-width: 768px) {

    .catalog-products {
        width: 100%;
    }

    .catalog-products-empty {
        width: 100%;
    }

    .catalog-products .home-catalog-product {
        width: 50%;
        margin-bottom: 0;
    }

    .catalog-menu {
        display: none;
    }

    .bread-crump {
        display: none;
    }

    .catalog-wrapper h1 {
        margin-bottom: 20px;
    }

}

@media screen and (max-width: 500px) {

    .catalog-products .home-catalog-product {
        width: 100%;
    }
}