.leasing-wrapper {
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto;
}

.leasing-text {
    text-align: center;
    margin-top: -15px;
    margin-bottom: 30px;
}

.leasing-container {
    display: flex;
    background: #fcfcfc;
    border: 1px solid #ececec;
    border-radius: 10px;
    overflow: hidden;
}

.leasing-calculator {
    width: 50%;
    padding: 20px;
    border-right: 1px solid #ececec;
}

.leasing-form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.leasing-form-box {
    width: calc(50% - 10px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

}

.leasing-form-title {
    font-size: 14px;
    font-weight: bold;
}

.leasing-form-box input {
    width: 100%;
    height: 50px;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    outline: none;
    padding-left: 15px;
    padding-right: 25px;
    margin: 5px 0;
    font-size: 15px;
    transition: border 0.3s;
}

.leasing-form-box input:focus {
    border: 1px solid black;
}

.leasing-form-box input.danger {
    color: #f44336
}

.leasing-form-symbol {
    position: absolute;
    right: 10px;
    font-size: 13px;
    color: #666666;
    top: 40px;
}

.leasing-form-text {
    font-size: 12px;
    color: #666666;
}

.leasing-form-term {
    position: relative;
    margin-bottom: 30px;
}

.leasing-form-term input[type='text'] {
    width: 100%;
    height: 50px;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    outline: none;
    padding-left: 15px;
    padding-right: 25px;
    margin: 5px 0;
    font-size: 15px;
    transition: border 0.3s;
}

.leasing-form-term input[type='text']:focus {
    border: 1px solid black;
}

.leasing-form-term input[type='text'].danger {
    color: #f44336
}

.leasing-form-term input[type='range'] {
    -webkit-appearance: none !important;
    appearance: none !important;
    background: red;
    width: 98%;
    height: 2px;
    position: absolute;
    top: 70px;
    left: 1%;
    right: 1%;
    background: black;
    cursor: pointer;
}

.leasing-form-term input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    appearance: none !important;
    background: var(--primary);
    height: 16px;
    width: 16px;
    border-radius: 8px;
    cursor: pointer;
}

.leasing-form-term input[type='range']::-moz-range-thumb {
    -webkit-appearance: none !important;
    appearance: none !important;
    background: var(--primary);
    height: 16px;
    width: 16px;
    border-radius: 8px;
    cursor: pointer;
}

.leasing-form-term-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.leasing-form-term-item {
    font-size: 12px;
    color: #666666;
}

.leasing-form-term-offer-text {
    font-size: 13px;
    margin-top: 10px;
    text-align: center;
    color: #555;
}

.leasing-result {
    padding: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.leasing-result-text {
    text-align: center;
    font-size: 16px;
    color: #222;
}

.leasing-result-payment {
    text-align: center;
    font-weight: 900;
    font-family: 'Red Hat Display';
    font-size: 40px;
    letter-spacing: 1px;
    margin-bottom: 15px;
}

.leasing-result-payment.hidden {
    opacity: 0.6;
}

.leasing-result-value {
    text-align: center;
    font-weight: 900;
    font-family: 'Red Hat Display';
    font-size: 16px;
    margin-bottom: 15px;
}

.leasing-result-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 280px;
}

.leasing-support {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.leasing-support-text {
    font-size: 13px;
    margin-top: 10px;
    text-align: center;
    color: #555;
}

.leasing-support-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
}

.leasing-support-item img {
    width: 80px;
    height: 50px;
    object-fit: contain;
}

.leasing-info {
    display: flex;
    flex-wrap: wrap;
    margin: 40px 0;
}

.leasing-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% / 6);
    background: white;
    height: 160px;
    padding: 10px;
}

.leasing-item {
    border-right: 1px solid #ececec;
}

.leasing-item:last-child {
    border-right: none;
}

.leasing-item img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    opacity: 0.3;
}

.leasing-item-text {
    font-size: 13px;
    text-align: center;
    margin-top: 10px;
    color: #555
}




@media screen and (max-width: 850px) {

    .leasing-container {
        flex-direction: column;
    }

    .leasing-calculator {
        width: 100%;
    }

    .leasing-result {
        width: 100%;
    }

    .leasing-item {
        width: 33.3333%;
    }

    .leasing-item:nth-child(3) {
        border-right: none;
    }

    .leasing-item:nth-child(1),
    .leasing-item:nth-child(2),
    .leasing-item:nth-child(3) {
        border-bottom: 1px solid #ececec;
    }

}


@media screen and (max-width: 500px) {

    .leasing-form-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 0;
    }

    .leasing-form-box {
        width: 100%;
        margin-bottom: 30px;
    }

    .leasing-result-payment {
        font-size: 30px;
    }

}

@media screen and (max-width: 350px) {

    .leasing-item {
        height: 100px;
    }

    .leasing-item img {
        width: 30px;
        height: 30px;
    }

    .leasing-item-text {
        font-size: 10px;
    }
}