.button {
    background: var(--primary);
    margin: 7px 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    transition: box-shadow 0.3s;
}

.button.disabled {
    cursor: default;
    opacity: 0.8;
}

.button:hover {
    box-shadow: 0 0 10px 1px var(--primary);
}

.button.disabled:hover {
    box-shadow: none;
}