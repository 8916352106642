.pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination div.pagination-item {
    width: 36px;
    height: 36px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    border: 1px solid #e9e9e9;
    cursor: pointer;
    transition: background-color 0.5s, border 0.5s;
    font-size: 13px;
    font-weight: bold;
    color: #222
}

.pagination div.pagination-item img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.pagination div.pagination-item.pagination-active,
.pagination div.pagination-item.pagination-active:hover {
    background: var(--primary);
    border: 1px solid var(--primary);
    color: white;
}

.pagination div.pagination-item:hover {
    background-color: #e9e9e9;
}

.pagination div.pagination-item.pagination-left-arrow {
    padding-right: 4px;
}

.pagination div.pagination-item span {
    font-size: 20px;
}

.pagination-dots {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #141414
}

.pagination-disabled {
    opacity: 0.5;
    cursor: auto;
}

.pagination-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pagination-box span {
    font-size: 12px;
    margin-top: 10px;
    color: #999
}