.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    transition: background-color 0.4s, box-shadow 0.4s;
}

.header.is-sticky {
    background: white;
    box-shadow: 0 0 20px #999;
}

/* Top Styles */
.top,
.is-sticky .top {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #c8c8c8;
    transition: background-color 0.3s;
}

.header-dark .top {
    border-bottom: 0.5px solid #b1b1b1;
}

.top-wrapper {
    height: 40px;
    max-width: 1240px;
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-social {
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-social a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 10px;
    overflow: hidden;
    margin-left: 10px;
}

.top-social img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
    filter: invert(0);
}

.header.is-sticky .top-social img {
    filter: invert(0);
}

.header-dark .top-social img {
    filter: invert(1);
}

.top-social a.map img {
    filter: grayscale(1);
    opacity: 1;
}

.header-dark .top-social a.map img {
    filter: grayscale(1);
    opacity: 1;
}

.header.is-sticky .top-social a.map img {
    filter: grayscale(1);
    opacity: 1;
}

.top-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -8px;
}

.top-menu a {
    text-transform: uppercase;
    font-size: 14px;
    margin: 8px;
    padding: 4px 2px;
    color: #222;
    font-weight: bold;
    position: relative;
    overflow: hidden;
}

.header-dark .top-menu a {
    color: white;
}

.header.is-sticky .top-menu a {
    color: #222;
}

.top-menu a::after {
    content: "";
    position: absolute;
    height: 1.5px;
    width: 100%;
    background: #000;
    z-index: 1;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translateX(-100%);
    transition: transform 0.3s;
}

.header-dark .top-menu a::after {
    background: white;
}

.header.is-sticky a::after {
    background: #000;
}

.top-menu a:hover::after {
    transform: translateX(0);
}



/* Mobile Top Styles */
.top-buttons {
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-menu-burger {
    width: 50px;
    height: 50px;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
}

.top-menu-burger img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.header-dark .top-menu-burger img {
    filter: invert(1);
}

.header.is-sticky .top-menu-burger img {
    filter: invert(0);
}

.top-back {
    width: 50px;
    height: 50px;
    display: none;
    justify-content: center;
    align-items: center;
    margin-left: -10px;
}

.top-back img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.top-logo {
    display: none;
    justify-content: flex-start;
    align-self: center;
    cursor: pointer;
    margin-top: 10px;
}

.top-logo-text {
    font-family: 'Red Hat Display';
    font-weight: 900;
    font-size: 14px;
    line-height: 14px;
    width: 100px;
    height: 50px;
    text-transform: uppercase;
    margin-left: 5px;
    user-select: none;
    -webkit-user-select: none;
    color: #222;
    transition: color 0.5s;
}

.header-dark .top-logo-text {
    color: #fff;
}

.header.is-sticky .top-logo-text {
    color: #222;
}

@media screen and (max-width: 950px) {

    .top-wrapper {
        height: 60px;
    }

    .top-social {
        display: none;
    }

    .top-menu-burger {
        display: flex;
    }

    .top-menu {
        display: none;
    }

    .top-logo {
        display: flex;
    }

    .top-back {
        display: flex;
    }

    .header-dark .top.search-box {
        border-bottom: 0.5px solid #b1b1b1;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .header.is-sticky .top.search-box {
        background-color: transparent;
    }

}


/* Header Styles */
.header-wrapper {
    max-width: 1240px;
    padding: 0 20px;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: height 0.4s, background-color 0.3s;
}

.header.is-sticky .header-wrapper {
    height: 60px;
}

.header-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
}

.header-logo {
    display: flex;
    justify-content: flex-start;
    align-self: center;
    cursor: pointer;
    margin-top: 10px;
    transition: transform 0.4s, margin 0.4s;
}

.header.is-sticky .header-logo {
    transform: scale(0.7);
    margin-left: -30px;
    margin-top: 5px;
}

.header-logo-text {
    font-family: 'Red Hat Display';
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    width: 150px;
    height: 70px;
    text-transform: uppercase;
    margin-left: 10px;
    user-select: none;
    -webkit-user-select: none;
    color: #222;
    margin-top: -5px;
    transition: color 0.5s;
}

.header-dark .header-logo-text {
    color: #fff;
}

.header.is-sticky .header-logo-text {
    color: #222;
}


.header-search {
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 400px;
}

.header-dark .header-search {
    opacity: 0.8;
    backdrop-filter: blur(5px);
}

.header.is-sticky .header-search {
    opacity: 1;
    backdrop-filter: blur(0);
    height: 40px;
}

.header-search input {
    height: 50px;
    width: 100%;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 65px;
    border-radius: 10px;
    border: 1px solid #999;
    outline: none;
    transition: height 0.4s;
}

.header-search input::placeholder {
    color: #888
}

.header.is-sticky .header-search input {
    height: 45px;
}

.header-search-button {
    width: 50px;
    height: 50px;
    border: none;
    outline: none;
    background: var(--primary);
    border-radius: 0 10px 10px 0;
    position: absolute;
    z-index: 1;
    right: 0;
    cursor: pointer;
    transition: box-shadow 0.3s, height 0.4s, width 0.4s;
}

.header.is-sticky .header-search-button {
    width: 45px;
    height: 45px;
}

.header-search-button:hover {
    box-shadow: 0 0 10px var(--primary);
}

.header-search img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    filter: invert(1);
}


/* Header Buttons */
.header-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-phone {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
}

.header-phone img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-top: 3px;
    margin-right: 5px;
}

.header-dark .header-phone img {
    filter: invert(1)
}

.header.is-sticky .header-phone img {
    filter: invert(0)
}

.header-phone-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.header-phone-text {
    color: #777;
    font-size: 13px;
    padding-left: 4px;
    padding-bottom: 1px;
}

.header-dark .header-phone-text {
    color: #fff;
}

.header.is-sticky .header-phone-text {
    color: #222;
}

.header-phone-value {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 17px;
    color: #222;
}

.header-dark .header-phone-value {
    color: #fff;
}

.header.is-sticky .header-phone-value {
    color: #222;
}


.header-request {
    background: var(--primary);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 10px;
    cursor: pointer;
    width: 180px;
    text-align: center;
    transition: box-shadow 0.3s;
    margin-left: 20px;
}

.header-request:hover {
    box-shadow: 0 0 10px var(--primary);
}

.header-dark .header-request {
    opacity: 0.9;
    backdrop-filter: blur(5px);
}

.header.is-sticky .header-request {
    opacity: 1;
    backdrop-filter: blur(0);
    height: 45px;
}


@media screen and (max-width: 950px) {

    .header {
        background: white;
        box-shadow: 0 0 20px #fafafa;
    }

    .header.is-sticky {
        background: white;
        box-shadow: 0 0 20px #cacaca;
    }

    .header-dark {
        background: transparent;
        box-shadow: none;
    }

    .header-wrapper {
        height: 80px;
        transition: height 0.3s, background-color 0.3s;
    }

    .header.is-sticky .header-wrapper {
        height: 80px;
    }

    .header-wrapper.visible {
        display: flex;
        height: 80px;
    }

    .header-dark .header-wrapper.visible {
        border-bottom: 0.5px solid #b1b1b1;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .header.is-sticky .header-wrapper.visible {
        background-color: transparent;
    }

    .header-dark .header-search {
        opacity: 1;
    }

    .header-wrapper.hidden {
        display: none;
        height: 0;
    }

    .header-container {
        width: 100%;
    }

    .header-buttons {
        display: none;
    }

    .header-search {
        width: 100%;
    }

    .header-logo {
        display: none;
    }

    .header.is-sticky .header-search,
    .header.is-sticky .header-search input {
        height: 50px;
    }

    .header.is-sticky .header-search-button {
        width: 50px;
        height: 50px;
    }

}


.mobile-menu {
    display: none;
}

@media screen and (max-width: 950px) {

    .mobile-menu {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        background: white;
        width: 300px;
        box-shadow: 0 0 20px #cacaca;
        z-index: 100;
        transform: translateX(350px);
        transition: transform 0.3s;
        display: block;
    }

    .mobile-menu.active {
        transform: translateX(0);
    }

    .mobile-menu-container {
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
    }

    .mobile-menu-container a {
        border-bottom: 1px solid #cacaca;
        text-transform: uppercase;
        font-size: 14px;
        color: #222;
        height: 48px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;
    }

    .mobile-menu-top {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 10px;
        padding-top: 10px;
    }

    .mobile-menu-close {
        display: flex;
        align-content: flex-end;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mobile-menu-close img {
        width: 18px;
        height: 18px;
        object-fit: contain;
    }
}