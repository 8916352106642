/* Parts */
.services-wrapper {
    max-width: 1240px;
    padding: 0 10px;
    margin: 0 auto;
}

.services-wrapper h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    margin-bottom: 20px;
}

.services-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.services-item {
    width: calc(100% / 5);
    object-fit: contain;
    padding: 10px;
}

.services-item-inner {
    background: #fcfcfc;
    border: 1px solid #ececec;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    border-radius: 10px;
    cursor: pointer;
    transition: box-shadow 0.4s, transform 0.4s;
    padding: 20px 10px;
}

.services-item-inner:hover {
    box-shadow: 0 0 20px #cacaca;
    transform: scale(1.04);
}

.services-item-inner img {
    width: 80px;
    height: 80px;
}

.services-item-text {
    font-size: 15px;
    margin-top: 15px;
    text-align: center;
    color: #222;
    text-transform: uppercase;
    min-height: 60px;
}

.services-item-button {
    border: 1px solid #999;
    width: calc(100% - 40px);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 1000;
    border-radius: 10px;
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s, box-shadow 0.3s;
    color: #222;
    margin-top: 20px;
}

.services-item-inner:hover .services-item-button {
    background: var(--primary);
    color: white;
}

@media screen and (max-width: 768px) {
    .services-item {
        width: calc(100% / 2);
    }
}

@media screen and (max-width: 500px) {
    .services-item-inner {
        height: auto;
    }
}


.service-information {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 40px;
}

.service-information-text {
    width: calc(100% - 350px);
    margin-right: 40px;
}

.service-information-text p {
    font-size: 14px;
    margin-bottom: 15px;
    text-indent: 20px;
}

.service-information-video {
    width: 300px;
    border-radius: 10px;
    overflow: hidden
}

.service-information-video video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.service-information-danger {
    background: #ff5722;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #ff9776;
    margin-bottom: 14px;
}

.service-information-danger-title {
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    color: white;
}

.service-information-danger-text {
    font-size: 13px;
    color: white;
    margin-top: 10px;
    text-align: center;
}



@media screen and (max-width: 768px) {
    .service-information {
        flex-direction: column;
    }

    .service-information-text {
        width: 100%;
        padding: 10px;
    }

    .service-information-video {
        width: 100%;
        padding: 10px;
    }

    .service-information-video video {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }


    .services-item-button {
        width: calc(100% - 5px);
        text-align: center;
        font-size: 13px;
    }

}