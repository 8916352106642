.footer {
    padding-top: 40px;
    background: #242424;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-wrapper {
    width: 100%;
    max-width: 1240px;
    padding: 0 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.footer-item {
    width: 30%;
    padding-left: 30px;
}

.footer-logo {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.footer-logo-text {
    font-family: 'Red Hat Display';
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    width: 150px;
    height: 70px;
    text-transform: uppercase;
    margin-left: 10px;
    user-select: none;
    -webkit-user-select: none;
    color: #fff;
    margin-top: -5px;
}

.footer-description {
    font-size: 14px;
    margin-top: 25px;
    color: #d3d3d3
}

.footer-description b {
    color: white;
}

.footer-social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}

.footer-social a {
    margin-right: 7px;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    overflow: hidden;
}

.footer-social img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    filter: invert(1);
}

.footer-social a.map img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    filter: grayscale(1);
}

.footer-text {
    font-weight: bold;
    color: white;
    margin-top: 20px;
    font-size: 14px;
}

.footer-title {
    font-weight: bold;
    font-size: 16px;
    color: white;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.footer-links {
    width: 20%;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.footer-links a {
    padding: 4px 0;
    margin-bottom: 3px;
    color: #e8e8e8;
    font-size: 14px;
    position: relative;
    overflow: hidden;
}

.footer-links a::after {
    content: "";
    position: absolute;
    height: 1.5px;
    width: 100%;
    background: #fff;
    z-index: 1;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translateX(-100%);
    transition: transform 0.3s;
}

.footer-links a:hover::after {
    transform: translateX(0);
}


.footer-contact {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
}

.footer-contact img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    filter: invert(1)
}

.footer-contact-box {
    padding-left: 10px;
    word-break: break-all;
}

.footer-contact-text {
    color: white;
    opacity: 0.9;
    font-size: 13px;
}

.footer-contact-value {
    font-weight: bold;
    color: white;
    font-size: 14px;
    margin-top: 3px;
}

.footer-bottom {
    width: 100%;
    padding: 20px;
    background: #141414;
    text-align: center;
    color: white;
    font-size: 13px;
    margin-top: 30px;
}





@media screen and (max-width: 768px) {
    .footer-wrapper {
        flex-wrap: wrap;
    }

    .footer-item,
    .footer-links {
        width: 50%;
        margin: 30px 0;
        padding-left: 0;
        padding-right: 30px;
    }

    .footer.footer-default {
        display: none;
    }

    .footer-bottom {
        padding-bottom: 80px;
    }
}

@media screen and (max-width: 500px) {

    .footer-wrapper {
        flex-direction: column;
    }

    .footer-item,
    .footer-links {
        width: 100%;
        margin: 30px 0;
        padding-left: 0;
        padding-right: 30px;
    }

}