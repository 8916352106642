.contact-wrapper {
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto;
}

.contact-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 40px;
}

.contact-form {
    width: 50%;
}

.contact-info {
    width: calc(50% - 30px);
    margin-right: 30px;
    margin-top: 7px;
}

.contact-info-inner {
    background: #fcfcfc;
    border: 1px solid #ececec;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.contact-element {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 10px 10px 10px;
    border-bottom: 0.7px solid #dadada;
    min-height: 50px;
    padding-bottom: 10px;
}

.contact-element img {
    width: 35px;
    height: 35px;
    object-fit: contain;
}

.contact-element img.smaller {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.contact-box {
    padding-left: 10px;
}

.contact-text {
    color: #555;
    font-size: 13px;
}

.contact-value {
    font-weight: bold;
    color: #141414;
    font-size: 14px;
    margin-top: 3px;
}


@media screen and (max-width: 750px) {
    .contact-container {
        flex-direction: column;
    }

    .contact-form {
        width: 100%;
    }

    .contact-info {
        width: 100%;
        margin-right: 0;
    }
}