.home-slide {
    overflow: hidden;
    position: relative;
    width: 100vw;
    height: 100vh;
}

.home-slide-inner {
    white-space: nowrap;
    transition: transform 0.5s;
}

.home-slide-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100vw;
    height: 100vh;
}

.home-slide-item-inner {
    background: #f7f8fe;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    transition: transform 0.5s, box-shadow 0.5s;
}

.home-slide-item-inner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-slide-item-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
    top: 0;
}

.home-slide-item-box {
    position: absolute;
    top: calc(50% - 100px);
    left: calc(50% - 580px);
    width: 40%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    white-space: pre-line;
}

.home-slide-item-title {
    font-size: 42px;
    color: white;
    font-weight: bold;
    margin-bottom: 10px;
}

.home-slide-item-text {
    font-size: 16px;
    word-wrap: break-word;
    color: white;
}

.home-slide-item-button {
    background: var(--primary);
    height: 50px;
    padding: 0 20px;
    border-radius: 10px;
    opacity: 0.9;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #fff;
    margin-top: 15px;
    transition: box-shadow 0.4s;
}

.home-slide-item-button:hover {
    box-shadow: 0 0 10px var(--primary);
}

.slider-shadow {
    position: absolute;
    height: 180px;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

.home-slider-numbers {
    position: absolute;
    right: calc(50% - 590px);
    z-index: 2;
}

.home-slider-numbers::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
}

.home-slider-number-active {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
    background: var(--primary);
    border-radius: 5px;
    transition: top 0.4s, left 0.4s;
}

.home-slider-number {
    width: 40px;
    height: 40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    position: relative;
    z-index: 3;
}



@media screen and (max-width: 768px) {

    .home-slide-inner {
        position: relative;
        z-index: 3;
    }

    .home-slide-item {
        height: 60vh;
    }

    .home-slide-item-box {
        top: 0;
        width: 90%;
        height: 60vh;
        z-index: 10;
    }

    .home-slide-item-title {
        font-size: 22px;
        text-shadow: 0 0 20px #222;
    }

    .home-slide-item-text {
        font-size: 12px;
        text-shadow: 0 0 20px #222;
    }

    .home-slide {
        height: 60vh;
    }

    .home-slider-numbers {
        right: auto;
        top: auto;
        bottom: auto;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}